
html, body {
    overflow: auto;
    max-height: 100%;
    max-width: 100%;
}

h1 {
    font-size: 55px;
}

@font-face {
    font-family: myFirstFont;
    src: url('../assets/lemon-jelly-font/LemonJellyPersonalUse-dEqR.ttf');
}

.aboutMe {
    opacity: 0.7;
    background-color: black;
    font-family: "Times New Roman", serif;
}

.aboutMe p {
    border: wheat 1px solid;



}

.aboutMeH1 {
 text-decoration: underline;
}

.middleDiv{
 background-color: black;
    opacity: 0.8;
}
.topDiv{
    background-color: black;
    opacity: 0.8;
}
.bottomDiv{
    background-color: black;
    opacity: 0.8;
}

img {
    display: block;
    margin: auto;
}
.icons {
   display: flex;
}

.swfimg {
    display: flex;
    width: 80%;
    height: auto;
    margin: auto;
}
.amuimg {
    display: flex;

}
