
@font-face {
    font-family: myFirstFont;
    src: url('../assets/lemon-jelly-font/LemonJellyPersonalUse-dEqR.ttf');
}

.home {
    color: deepskyblue;
    font-size: 55px;
    text-align: center;

    text-decoration: underline;

    margin: 20px;
    padding: 100px;
    animation-name : mymove;
    animation-iteration-count : infinite;
    animation-direction : alternate;
    animation-duration : 2s;
    font-family: myFirstFont;
}
.moveArrow {
    animation-name : mymove;
    animation-iteration-count : infinite;
    animation-direction : alternate;
    animation-duration : 2s;
}

@keyframes mymove {
    from {transform: translateY(25px);}
    to {transform: translateY(50px);}
}
body {
    background-image: url("../assets/wp4676574-4k-pc-wallpapers.jpg");
    position: fixed;
    top: 0;
    left: 0;


    min-width: 100%;
    min-height: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;background-position:50% 50%;
}



